<!-- Popup thông tin chi tiết CCQ đang giao dịch -->
<div class="modal-contents new-fund">
  <!-- Modal Header -->
  <div class="modal-header">
    <div class="title__group">
      <img [src]="product.owner?.avatarUrl !== null ? product.owner?.avatarUrl : ' '" alt="" />
      <div class="title__group--detail">
        <div class="d-flex align-items-center" *ngIf="!isDevice">
          <label><strong>{{product.name}} ({{product.shortName}})</strong></label>
          <label id="label-new-product" class="ml-2" *ngIf="product.isNewProduct">New</label>
        </div>
        <div class="d-flex flex-row" *ngIf="isDevice">
          <label><strong>{{product.shortName}}</strong></label>
          <label id="label-new-product" class="ml-2" *ngIf="product.isNewProduct">New</label>
        </div>

        <span>{{product.owner?.shortName}}</span>
      </div>
    </div>
    <button type="button" class="btn close-btn" (click)="dismiss()" *ngIf="!isDetailFullPage">
      <img src="./assets/images/icon/ic-close-modal.svg" alt="" />
    </button>
  </div>

  <!-- Modal body -->
  <div class="modal-body horizon-mobile">
    <div class="row modal-row">
      <div class="col-lg-7 col-sm-12 col-md-12 col-12">
        <div class="flex-between">
          <div class="card">
            <span>{{'Giá gần nhất' | translate}}</span>
            <div class="group-text">
              <label class="price" for="">{{(product.nav) | money}} VND</label>
              <label class="small-des" for="">{{'Cập nhật ngày' | translate}} {{product.extra?.lastNAVDate ?
                (product.extra?.lastNAVDate | date_vn: 'dd/MM/yyyy ') : 'N/A'}}
              </label>
            </div>
          </div>
          <button class="btn btn--blue" type="button" (click)="buy()" prevent-multi-clicks *ngIf="!isDetailFullPage">
            {{'Mua' | translate}}
          </button>
        </div>

        <div class="chart-info">
          <div class="chart-title">
            <label for="">{{'Biểu đồ tăng trưởng' | translate}} NAV</label>
            <span class="navChange" *ngIf="navChange && isDevice"
              [ngClass]="navChange > 0 ? 'up' : navChange === 0 ? 'pledge' : 'down'">
              <span class="up" *ngIf="navChange > 0 ">+</span>{{navChange}}%</span>
          </div>
          <nav class="nav-sidebar tab-scrollX">
            <ul class="nav tabs nav-tabs">
              <li class="fund-filter" *ngIf="!isDevice">
                <a class="btn btn-filter nav-link" (click)="getDataChart('3')" prevent-multi-clicks
                  [ngClass]="typeDataChart === '3' && 'active'">
                  3 {{'tháng' | translate}}
                </a>
              </li>
              <li class="fund-filter">
                <a class="btn btn-filter nav-link" (click)="getDataChart('6')" prevent-multi-clicks
                  [ngClass]="typeDataChart === '6' && 'active'">
                  6 {{'tháng' | translate}}
                </a>
              </li>
              <li class="fund-filter">
                <a class="btn btn-filter nav-link" (click)="getDataChart('12')" prevent-multi-clicks
                  [ngClass]="typeDataChart === '12' && 'active'">
                  12 {{'tháng' | translate}}
                </a>
              </li>
              <li class="fund-filter">
                <a class="btn btn-filter nav-link" (click)="getDataChart('36')" prevent-multi-clicks
                  [ngClass]="typeDataChart === '36' && 'active'">
                  36 {{'tháng' | translate}}
                </a>
              </li>
              <li class="fund-filter">
                <a class="btn btn-filter nav-link" (click)="getDataChart('ALL')" prevent-multi-clicks
                  [ngClass]="typeDataChart === 'ALL' && 'active'">
                  {{'Tất cả' | translate}}
                </a>
              </li>
            </ul>
            <span class="navChange" *ngIf="navChange && !isDevice"
              [ngClass]="navChange > 0 ? 'up' : navChange === 0 ? 'pledge' : 'down'">
              <span class="up" *ngIf="navChange > 0 ">+</span>{{navChange}}%</span>
          </nav>
          <div class="chart d-flex flex-column">
            <app-chart-js-line-chart [chartData]="chartData"
              *ngIf="chartData.datasets[0]?.data?.length; else noDataChart">
            </app-chart-js-line-chart>
            <ng-template #noDataChart>
              <div class="d-flex flex-column align-items-center justify-content-center" style="min-height: 400px">
                <img src="./assets/images/icon/nodata-chart.svg" height="100" />
                <span> {{'Không có dữ liệu biểu đồ' | translate}}</span>
              </div>
            </ng-template>
          </div>
        </div>

        <div class="col-lg-12 col-sm-12 col-md-12 col-12" *ngIf="product.description">
          <label class="pt-3 pb-3">{{product.description}}</label>
        </div>
      </div>

      <div class="col-lg-5 col-sm-12 col-md-12 col-12">
        <div class="content-search tab-scrollX" [ngClass]="isDevice ? 'tab-scrollX-mobile' : ''">
          <div class="asset-tabs  mb-2">
            <tab-list [list]="tabList" (tabChange)="changeTab($event)" [isNoRoute]="true"></tab-list>
          </div>
          <div>
            <div class="tab-content">
              <div class="tab-content-1" *ngIf="tab === TABS.OVERVIEW">
                <div *ngIf="!isDevice">
                  <div class="table-responsive">
                    <table class="table">
                      <tbody class="table__item">
                        <tr>
                          <td>
                            <label for="">{{'Tổ chức phát hành' | translate}}</label>
                          </td>
                          <td><span>{{product.owner?.shortName}}</span></td>
                        </tr>
                        <tr>
                          <td>
                            <label for="">{{'Tài sản đầu tư' | translate}}</label>
                          </td>
                          <td><span>{{product.assets || '_'}}</span></td>
                        </tr>
                        <tr>
                          <td><label for="">{{'Tài sản ròng' | translate}}</label></td>
                          <td><span>{{product.fundReport?.toString || '_'}}</span></td>
                        </tr>
                        <tr>
                          <td>
                            <label for="">{{'Ngày khớp lệnh' | translate}}</label>
                          </td>
                          <td>
                            <span>{{product.transactionDate ?
                              (product.transactionDate || '') + approveDays :
                              '_'}}</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label for="">{{'Phiên khớp lệnh tiếp theo' |
                              translate}}</label>
                          </td>
                          <td>
                            <span>{{product.productTradingSession?.tradingTimeString}}</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label for="">{{'Đặt lệnh và chuyển tiền mua' |
                              translate}}</label>
                          </td>
                          <td>
                            <span>{{product.productTradingSession?.closedOrderBookString}}</span>
                          </td>
                        </tr>
                        <tr *ngIf="product.customField">
                          <td>
                            <label for="">{{product.customField | translate}}</label>
                          </td>
                          <td><span>{{product.customValue}}</span></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="block block-up" *ngIf="isDevice">
                  <div class="row">
                    <div class="col-lg-3 col-sm-3 col-md-3 col-12">
                      <div class="block-item">
                        <label for="">{{'Tổ chức phát hành' | translate}}</label>
                        <span>{{product.owner?.shortName}}</span>
                      </div>
                    </div>
                    <div class="col-lg-5 col-sm-5 col-md-5 col-12">
                      <div class="block-item">
                        <label for="">{{'Tài sản đầu tư' | translate}}</label>
                        <span>{{product.assets || '_'}}</span>
                      </div>
                    </div>
                    <div class="col-lg-5 col-sm-5 col-md-5 col-12">
                      <div class="block-item">
                        <label for="">{{'Tài sản ròng' | translate}}</label>
                        <span>{{product.fundReport?.toString || '_'}}</span>
                      </div>
                    </div>
                    <div class="col-lg-4 col-sm-4 col-md-4 col-12">
                      <div class="block-item">
                        <label for="">{{'Ngày khớp lệnh' | translate}}</label>
                        <span>{{product.transactionDate ? (product.transactionDate
                          || '') + approveDays : '_'}}</span>
                      </div>
                    </div>
                    <div class="col-lg-3 col-sm-3 col-md-3 col-12">
                      <div class="block-item">
                        <label for="">{{'Phiên khớp lệnh tiếp theo' | translate}}</label>
                        <span>{{product.productTradingSession?.tradingTimeString}}</span>
                      </div>
                    </div>
                    <div class="col-lg-5 col-sm-5 col-md-5 col-12">
                      <div class="block-item">
                        <label for="">{{'Đặt lệnh và chuyển tiền mua' | translate}}</label>
                        <span>{{product.productTradingSession?.closedOrderBookString}}</span>
                      </div>
                    </div>
                    <div class="col-lg-4 col-sm-4 col-md-4 col-12" *ngIf="product.customField">
                      <div class="block-item">
                        <label for="">{{product.customField | translate}}</label>
                        <span>{{product.customValue}}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="fee-list">
                  <div class="row">
                    <div class="col-lg-12 col-sm-6 col-md-6 col-md-6 md--border__right"
                      *ngIf="product.feeBuys?.length > 0"
                      [ngClass]="!product.normalTimeHolds || ( product.normalTimeHolds && product.normalTimeHolds.length === 0) ? 'col-sm-6' : 'col-sm-12'">
                      <detail-fee [option]="optionBuyFee" [fees]="product.feeBuys"></detail-fee>
                    </div>
                    <div class="col-lg-12 col-sm-6 col-md-6"
                      *ngIf="product.normalTimeHolds && product.normalTimeHolds.length > 0"
                      [ngClass]="!product.feeBuys || ( product.feeBuys && product.feeBuys.length === 0) ? 'col-sm-6' : 'col-sm-12'">
                      <detail-fee [option]="optionSellFee" [fees]="product.normalTimeHolds"></detail-fee>
                    </div>

                    <div class="col-lg-12 col-sm-6 col-md-6" *ngIf="transferFees.length > 0">
                      <div class="table-tab row goal__fund">
                        <div class="col-12 mt-3">
                          <div class="row modal-row modal-heading">
                            <div class="col-6 col-sm-6 tt-buy-hgt">
                              <div class="d-flex flex-column">
                                <label class="title-fee">{{'Quỹ mục tiêu' | translate}}</label>
                              </div>
                            </div>
                            <div class="col-6 col-sm-6 text-right tt-buy-hgt">
                              <label class="title-fee">{{'Phí chuyển đổi' | translate}}</label>
                              <br />
                            </div>
                          </div>
                          <div class="row row-color" *ngFor="let itemFee of transferFees">
                            <div class="col-6 col-sm-6 tt-buy-hgt no-padding-right">
                              <div class="d-flex flex-column">
                                <span>{{itemFee.product?.shortName}}</span>
                                <span style="color: #8a97a8; font-size: 14px">{{itemFee.product?.fundAssetType | translate}}</span>
                              </div>
                            </div>

                            <div class="col-6 tt-buy-hgt text-right">
                              <div class="row">
                                <div class="col-12" *ngFor="let item of itemFee.fees, index as index">
                                  <label *ngIf="!item.isOneFee">
                                    <label *ngIf="index === 0 && index !== itemFee.fees?.length - 1">
                                      {{item.endRelationalOperator?.name}}
                                      {{item.endVolume | number}}
                                    </label>
                                    <label *ngIf="index === itemFee.fees?.length -1">
                                      {{item.beginRelationalOperator?.name}}
                                      {{item.beginVolume | number}}
                                    </label>
                                    <label *ngIf="index !== 0 && index !== itemFee.fees?.length -1">
                                      {{item.beginVolume | money}} -
                                      {{item.endVolume | money}}
                                    </label>
                                    {{item.feeUnitTypeName | translate}}:
                                  </label>
                                  <label class="label-name ml-2">{{item.fee}}%</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-content">
              <div class="tab-content-2 table-tab" *ngIf="tab === TABS.TOP_HOLDING">
                <top-holding-fund [topHolding]="product.productTopHoldingList"
                  [updateTime]="product.productFund?.updateAssetHoldingTime"
                  [bondList]="product.productTopHoldingBondList"></top-holding-fund>
              </div>
            </div>
            <div class="tab-content">
              <div class="tab-content-3 table-tab" *ngIf="tab === TABS.HOLDING_INDEX">
                <holding-index></holding-index>
              </div>
            </div>
            <div class="tab-content">
              <div class="tab-content-4 table-tab" *ngIf="tab === TABS.INDUSTRY_DISTRIBUTION">
                <industry-distribution [updateTime]="product.productFund?.updateAssetHoldingTime"
                  [industryDistribution]="product.productIndustriesHoldingList"></industry-distribution>
              </div>
            </div>
            <div class="tab-content">
              <div class="tab-content-5 table-tab" *ngIf="tab === TABS.ASSET_DISTRIBUTION">
                <asset-distribution [assetDistribution]="product.productAssetHoldingList"
                  [updateTime]="product.productFund?.updateAssetHoldingTime"></asset-distribution>
              </div>
            </div>
            <div class="tab-content">
              <div class="tab-content-5 table-tab" *ngIf="tab === TABS.DOCUMENTS">
                <documents-fund [documents]="product.productDocuments"></documents-fund>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>