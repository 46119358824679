<div class="row" *ngIf="assetDistribution?.length; else noData">
  <div class="w-100 d-flex flex-column align-items-center">
    <div class="chart-wrapper-asset">
      <app-chart-js-pie-chart [chartData]="dataChart" 
          *ngIf="dataChart?.datasets[0]?.data?.length">
      </app-chart-js-pie-chart>
    </div>
    <div class="row w-100 mt-4 mb-3">
      <div class="col-8">
        <div style="font-weight: bold;">
           {{'Giá trị tài sản' | translate}} (GAV)
        </div>
      </div>
      <div class="col-4">
        <span style="font-weight: bold;"> {{'Tỉ trọng' | translate}} (%)</span>
      </div>
    </div>
    <div class="row w-100 mb-2" *ngFor="let item of assetDistribution; index as index">
      <div class="col-8">
        <div class="asset-item">
          <div class="color" [style.background-color]="item.assetType?.colorCode"></div>
          {{item.assetType?.name}}
        </div>
      </div>
      <div class="col-4 text-right">
        <span>{{item.assetPercent}}%</span>
      </div>
    </div>
    <div class="col-12 mt-3" *ngIf="updateTime">
      <span> {{'Theo báo cáo tháng' | translate}} {{updateTime}}</span>
    </div>
  </div>

</div>
<ng-template #noData>
  <div class="block-nodata">
    <img src="./assets/images/icon/nodata.png" alt="">
    <h5> {{'Không có dữ liệu' | translate}}</h5>
  </div>
</ng-template>