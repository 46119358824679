<div class="row" *ngIf="fees?.length">
  <div class="col-12 mt-3">
    <div class="row modal-row modal-heading">
      <div class="col-9 col-sm-9 tt-buy-hgt">
        <div class="group-title">
          <label class="title-fee">{{option?.valueTitle | translate}}</label>
          <span class="notice-text" *ngIf="option?.valueUnit"
            >({{option?.valueUnit | translate}})</span
          >
        </div>
      </div>
      <div class="col-3 col-sm-3 text-right tt-buy-hgt">
        <label class="title-fee">{{option?.feeTitle  | translate}}</label>
        <br />
        <!-- <span class="notice-text">({{option?.feeUnit}})</span> -->
      </div>
    </div>
    <div class="row row-color" *ngFor="let item of fees, index as index">
      <div class="col-10 col-sm-10 tt-buy-hgt no-padding-right">
        <label *ngIf="index === 0 && index !== fees.length-1">
          {{item.endRelationalOperator?.name}} {{item.endVolume | number}}
        </label>
        <label *ngIf="index === fees.length-1">
          {{item.beginRelationalOperator?.name}} {{item.beginVolume | number}}
        </label>
        <label *ngIf="index !== 0 && index !== fees.length-1">
          {{item.beginVolume | number}} - {{item.endVolume | number}}
        </label>
      </div>
      <div class="col-2 col-sm-2 text-right tt-buy-hgt">
        <label class="label-name">{{item.fee}}{{option?.feeUnit | translate}}</label>
      </div>
    </div>
    <!-- <div class="row row-color" *ngIf="!fees.length" style="justify-content: center;">
      <span>Không có phí</span>
    </div> -->
  </div>
</div>
